export function show_hide_page_intro_text() {
	var content = document.querySelector("[js-show-hide-text-for-mobile]");
	var button = document.querySelector("[js-toggle-read-more]");

	if (!content || !button) return;

	button.addEventListener("click", function () {
		if (content.style.maxHeight) {
			content.style.maxHeight = null;
			button.textContent = "Read More";
		} else {
			content.style.maxHeight = content.scrollHeight + "px";
			button.textContent = "Read Less";
		}
	});
}
