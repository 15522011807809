// This function is used to initialize the newsletter buttons on the page
export function initActiveCampaignScript() {
	// The URL of the script that needs to be loaded
	const newsletterOptScript = "https://tofino.activehosted.com/f/embed.php?id=7";
	const messageFormScript = "https://tofino.activehosted.com/f/embed.php?id=1";

	// This function creates a new script tag, sets its source to the provided URL, and appends it to the body of the document
	function loadScript(url) {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.src = url;
		document.body.appendChild(script);
	}

	// If the current page is the contact page, load the script
	if (window.location.href.endsWith("/contact/")) {
		loadScript(messageFormScript);
	}

	// Select all elements with the attr "[newsletter_show_modal]"
	const newsletterButtons = document.querySelectorAll("[js-newsletter_show_modal]");
	// For each selected element, add a click event listener
	newsletterButtons.forEach((button) => {
		button.addEventListener("click", function () {
			// When the button is clicked, load the script
			loadScript(newsletterOptScript);
		});
	});
}
