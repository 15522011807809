import { modernizeEnso } from "../../components/enso/script.js";
import { cleanup_img_tags } from "./utility.js";
import { initActiveCampaignScript } from "../../components/cta-newsletter/script.js";
import { setActiveForNavLinks } from "./utility.js";
import { sortLinksAlphaAsc } from "../../components/nav-sidebar/script.js";
import { mobileNav } from "../../components/nav-mobile/script.js";
import { show_hide_page_intro_text } from "../../components/page-intro/script.js";

document.addEventListener("DOMContentLoaded", function () {
	try {
		modernizeEnso();
	} catch (error) {
		console.error("Error running modernizeEnso:", error);
	}

	try {
		cleanup_img_tags();
	} catch (error) {
		console.error("Error running cleanup_img_tags:", error);
	}

	try {
		setActiveForNavLinks();
	} catch (error) {
		console.error("Error running setActiveForNavLinks:", error);
	}

	try {
		mobileNav();
	} catch (error) {
		console.error("Error running mobileNav:", error);
	}

	try {
		initActiveCampaignScript();
	} catch (error) {
		console.error("Error initActiveCampaignScript:", error);
	}

	try {
		sortLinksAlphaAsc();
	} catch (error) {
		console.error("Error sortLinksAlphaAsc:", error);
	}

	try {
		show_hide_page_intro_text();
	} catch (error) {
		console.error("Error sortLinksAlphaAsc:", error);
	}
});
