// Function - Modernize Enso
export function modernizeEnso() {
	try {
		let ensoElements = [...document.querySelectorAll(".enso-overlay-link")];
		if (ensoElements.length > 0) {
			ensoElements.forEach((element) => {
				let ensoOverlayContentElement = element.closest(".enso-overlay-content");
				let ensoElement = ensoOverlayContentElement.closest("[enso]");
				copyEnsoLinkToParentAttribute(element);
				element.remove();
				unWrap(ensoOverlayContentElement);
				ensoOverlayContentElement.remove();
				addTabKeyDownEvent(ensoElement);
			});
		}
	} catch (error) {
		console.error(error);
	}
}

// FUNCTION - Move Enso Link to parent element with enso attribute
function copyEnsoLinkToParentAttribute(element) {
	let getLink = element.getAttribute("onclick");
	getLink = getLink.replace('self.location.href="', "").replace('"', "");
	let targetEnso = element.closest("[enso]");
	if (targetEnso) {
		targetEnso.setAttribute("enso", getLink);
	}
}

// Function - Upwrap element
function unWrap(element) {
	const parent = element.parentNode;
	while (element.firstChild) {
		parent.insertBefore(element.firstChild, element);
	}
}

// Function - Add in tab keydown event
function addTabKeyDownEvent(element) {
	let overlayVisible = false;
	document.addEventListener("keydown", (event) => {
		if (event.key === "Tab" && element) {
			event.preventDefault();
			overlayVisible = !overlayVisible;
			if (overlayVisible) {
				element.classList.add("js-enso-on");
				addEnsoClickListener(element);
			} else {
				element.classList.remove("js-enso-on");
				removeEnsoClickListener(element);
			}
		}
	});
}

// Function - what to do when link is clicked
function setupLink(e) {
	e.preventDefault();
	let goToUrl = this.getAttribute("enso"); // this was the key
	window.location.href = goToUrl;
}

// Function - Add click event listener
function addEnsoClickListener(element) {
	element.addEventListener("click", setupLink, false);
}

// Function - Remove click event listener
function removeEnsoClickListener(element) {
	element.removeEventListener("click", setupLink);
}
