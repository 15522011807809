export function mobileNav() {
	// ------------------------------------------------------
	// Mobile Nav, main container class changes
	// ------------------------------------------------------

	// Guard against screens larger than 768px
	if (window.matchMedia("(min-width: 768px)").matches) return;

	// Select
	const buttonClose = document.querySelector("[js-nav-mobile-close]");
	const nav = document.querySelector("[js-nav-mobile]");
	const container = document.querySelector("[js-container]");

	// Check
	if (!container || !nav || !buttonClose) return;

	// Handle
	buttonClose.addEventListener("click", () => {
		nav.classList.toggle("show");
		container.classList.toggle("show");
	});

	// ------------------------------------------------------
	// Hamburger button css class changes based on logo position
	// ------------------------------------------------------

	// Select
	const logo = document.querySelector("[js-logo-watch]");
	const hamburger = buttonClose;

	// Check
	if (!logo) return;

	let observer = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				if (!entry.isIntersecting) {
					hamburger.classList.add("bg-dark");
				} else {
					hamburger.classList.remove("bg-dark");
				}
			});
		},
		{ rootMargin: "250px", threshold: 0.1 }
	);

	observer.observe(logo);
}
