export function sortLinksAlphaAsc() {
	const linksContainer = document.querySelector("[js-sort-links-asc]");
	if (linksContainer) sortLinks(linksContainer);
}

function sortLinks(linksContainer) {
	let links = Array.from(linksContainer.querySelectorAll("a"));
	links.sort((a, b) => a.textContent.localeCompare(b.textContent));
	linksContainer.innerHTML = "";
	links.forEach((link) => linksContainer.appendChild(link));
}
