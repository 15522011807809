export function setActiveForNavLinks() {
	// Select
	const siteLinks = document.querySelectorAll("[js-set-active]");
	const path = window.location.pathname;

	// Check
	if (!siteLinks) return;

	// Handle
	siteLinks.forEach((element) => {
		// Select
		const href = element.getAttribute("href");

		// Check
		if (!href) return;

		// Handle
		if (path === href) element.classList.add("active");
	});
}

export function cleanup_img_tags() {
	processImages("img", "/tofino");
}

function processImages(selector, path) {
	const images = document.querySelectorAll(selector);
	images.forEach((image) => {
		const dataSrc = image.getAttribute("data-src");
		if (dataSrc !== null && (dataSrc.trim() === "" || dataSrc.endsWith(path))) {
			removeImage(image);
		} else if (dataSrc !== null) {
			updateImageSrc(image, dataSrc);
		}
	});
}

function removeImage(image) {
	image.remove();
}

function updateImageSrc(image, dataSrc) {
	image.setAttribute("src", dataSrc);
	const dataSrcset = image.getAttribute("data-srcset");
	if (dataSrcset) {
		image.setAttribute("srcset", dataSrcset);
	}
}

export function initializeLazyLoad() {
	document.addEventListener("DOMContentLoaded", function () {
		const observer = new IntersectionObserver(
			(entries, observer) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						const img = entry.target;
						const src = img.getAttribute("src");
						img.removeAttribute("loading"); // Optional, remove if not needed
						img.src = src;
						observer.unobserve(img);
					}
				});
			},
			{
				rootMargin: "200px", // Adjust this value based on when you want images to load
				threshold: 0.01
			}
		);

		const lazyImages = document.querySelectorAll('img[loading="lazy"]');
		lazyImages.forEach((img) => {
			observer.observe(img);
		});
	});
}
